import React from 'react'
import type { BoxProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'

interface LinkProps extends BoxProps {
  children: string
  href: string
}

export default (props: LinkProps) => {
  return (
    <Box
      as="a"
      color="cyan.400"
      textDecoration="underline"
      cursor="pointer"
      {...props}
    />
  )
}
