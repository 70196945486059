import { Link } from 'gatsby'
import React from 'react'
import { Box, Flex, HStack } from '@chakra-ui/react'
import { AiTwotonePhone } from 'react-icons/ai'
import schema from '../seo/schema.json'

interface HeaderProps {
  siteTitle?: string
  shortTitle?: string
}

const Header = ({ shortTitle, siteTitle }: HeaderProps) => (
  <Flex as="header" flex="1" py={[10, 12, 16]}>
    <Box flex="2">
      <Box as="h2" fontSize="xl" m="0" fontWeight="extrabold">
        <Link to="/">
          <Box as="span" display={['block', 'none']}>
            {shortTitle}
          </Box>
          <Box as="span" display={['none', 'block']}>
            {siteTitle}
          </Box>
        </Link>
      </Box>
    </Box>
    <HStack flex="2" justifyContent="flex-end">
      <Flex dir="row" as="address">
        <Flex
          as="a"
          dir="row"
          href={`tel:+${schema.telephone}`}
          fontStyle="normal"
          alignItems="center"
        >
          <Box as="span" color="gray.500">
            {schema.telephone}
          </Box>
          <Box ml="2" display="inline" as={AiTwotonePhone} />
        </Flex>
      </Flex>
    </HStack>
  </Flex>
)

export default Header
