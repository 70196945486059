/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container, Box, Flex } from '@chakra-ui/react'
import Header from './Header'
import Link from './Link'

import Image from './Image'

require('typeface-roboto-slab')

interface LayoutProps {
  children: React.ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  const data = useStaticQuery<GatsbyTypes.SiteTitleQueryQuery>(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          shortTitle
        }
      }
    }
  `)

  return (
    <Box
    // sx={{
    //   backgroundImage: `url(${background})`,
    //   backgroundRepeat: 'repeat-x',
    //   backgroundPosition: 'center top',
    // }}
    >
      <Container maxW="5xl" paddingX={[4, 8]}>
        <Box>
          <Header
            siteTitle={data?.site?.siteMetadata?.title}
            shortTitle={data?.site?.siteMetadata?.shortTitle}
          />
          <main>{children}</main>

          <Box
            as="footer"
            py="20"
            textAlign="center"
            borderTop="1px solid"
            borderTopColor="gray.300"
          >
            <Box w="80px" h="80px" display="inline-block">
              <Image />
            </Box>
            <p>Physio Praxis Nord</p>
            <p>Hufelandstr. 7, 17438 Wolgast</p>
            <p>Inhaberin Ricarda Lehmann</p>
            <Box py="0">
              <Link textDecoration="none" color="gray.500" href="/datenschutz">
                Datenschutzerklärung
              </Link>
            </Box>
            <p>© {new Date().getFullYear()}</p>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default Layout
